<template>
  <card class="shadow" body-classes="standard-card-body">
    <ul class="list-group list-group-flush">
      <list-group-item-component :label="$t('fields.status')">
        <template slot="value">
          <badge v-if="callCenterLead.status"
                 :type="callCenterLead.status | leadStatusColor"
                 class="text-uppercase">
            {{ $t('callcenter.status.' + callCenterLead.status) }}
          </badge>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.firstname')">
        <template slot="value">
          <label-theme-component>
            {{ callCenterLead | optional('lead.registry.surname') }}
            {{ callCenterLead | optional('lead.registry.name') }}
          </label-theme-component>
        </template>
      </list-group-item-component>

      <list-group-item-component :label="$t('fields.email')"
                                 :value="callCenterLead | optional('lead.registry.defaultEmail.email')"
      />

      <list-group-item-component :label="phones.length > 1 ? $t('fields.phones') : $t('fields.phone')">
        <template slot="value">
          <div class="d-flex flex-column">
            <label-theme-component v-for="(item,index) in phones" v-bind:key="index">
              {{ item.phone }}
            </label-theme-component>
          </div>
        </template>
      </list-group-item-component>

      <list-group-item-component v-if="callCenterLead.user_id" :label="$t('page.call_center_operator')">
        <template slot="value">
          <label-theme-component>
            {{ users[callCenterLead.user_id] | optional('firstname') }}
            {{ users[callCenterLead.user_id] | optional('lastname') }}
          </label-theme-component>
        </template>
      </list-group-item-component>

      <list-group-item-component v-if="callCenterLead.campaign && callCenterLead.campaign.name"
                                 :label="$t('page.campaign')"
                                 :value="callCenterLead.campaign.name"
      />
    </ul>
    <div slot="footer" v-if="showManageButton">
      <base-button block class="text-capitalize"
                   @click="$emit('onManageCallCenterLead', callCenterLead.id)">
        {{ $t('common.manage') }}
      </base-button>
    </div>
  </card>
</template>

<script>

import CallCenterLead from "@/models/callCenterLead";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Badge from "@/components/Badge";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import CallCenterLeadStatuses from "@/pages/CallCenter/resources/callCenterLeadStatuses";

export default {
  name: "CallCenterLeadCard",
  components: {LabelThemeComponent, ListGroupItemComponent, Badge, OctoIcon},
  props: {
    callCenterLead: {
      type: Object,
      default: () => this.$_.cloneDeep(CallCenterLead)
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      currentUser: 'auth/currentUser',
    }),

    showManageButton: function() {
      switch(this.callCenterLead.status) {
        case CallCenterLeadStatuses.free.value:
        case CallCenterLeadStatuses.public.value:
        case CallCenterLeadStatuses.unattainable.value:
          return true;

          default:
            return this.callCenterLead.user_id === this.currentUser.id;
      }

    },

    phones() {
      return this.callCenterLead?.lead?.registry?.phones || [];
    }
  }
}

</script>

<style scoped>

</style>
