<template>
  <div class="container-fluid">
    <div class="row align-items-center mb-4">
      <div class="col flex-grow-1 align-items-center">
        <ValidationObserver ref="phoneSearch">
          <ValidationProvider
            name="phone"
            rules="required|min:6"
            v-slot="{ passed, failed, errors }">
            <base-input
              :label="$t('fields.phone')"
              v-model="phone"
              type="text"
              :placeholder="$t('fields.type_to_search')"
              :error="errors[0]"
              :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
            </base-input>
          </ValidationProvider>
        </ValidationObserver>
      </div>
      <div class="col-auto d-flex align-items-start">
        <base-button link icon class="ml-auto text-capitalize mb-0" :disabled="false" @click="validate">
          <octo-icon icon="magnifying-glass"></octo-icon>
        </base-button>
      </div>
    </div>
    <div class="row" v-if="callCenterLeads && callCenterLeads.length > 0">
      <div class="col-12 col-md-6"
           v-for="(callCenterLead, index) in callCenterLeads"
           v-bind:key="`lead-card-${index}`"
      >
        <call-center-lead-card :call-center-lead="callCenterLead" @onManageCallCenterLead="manageCallCenterLead"/>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12">
        <no-data-component :label="$t('common.no_results')"/>
      </div>
    </div>
  </div>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import CallCenterLeadCard from "./OperatorComponents/CallCenterLeadCard";
import {mapMutations} from "vuex";
import NoDataComponent from "../../components/NoDataComponent";

export default {
  name: "SearchRegistryPage",
  components: {NoDataComponent, BaseAlert, CallCenterLeadCard, OctoIcon},
  data() {
    return {
      phone: '',
      endpoints: endpoints,
      callCenterLeads: []
    }
  },
  methods: {

    ...mapMutations({
      setSkipIntro: 'call-center-operator/setSkipIntro',
    }),

    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.phoneSearch.validate()
          .then((success) => {
            if (!success) {
              reject();
            } else {
              this.$fullLoading.show();

              this.$api.post(endpoints.CALL_CENTER_GET_LEAD_BY_PHONE, {phone: this.phone})
                .then((resp) => {
                  this.callCenterLeads = resp.data;
                })
                .catch(() => {
                  this.callCenterLeads = [];
                  this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
                })
                .finally(() => {
                  this.$fullLoading.hide();
                });
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    manageCallCenterLead(callCenterLeadId) {
      this.$fullLoading.show();
      this.$api.put(endpoints.CALL_CENTER_MANAGE_FREE_LEAD.replace('{id}', callCenterLeadId))
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')})
          this.setSkipIntro(true)
          this.$fullLoading.hide();
          this.$router.push({name: 'operator.handle_lead'});
        })
        .catch(() => {
          this.$fullLoading.hide();
          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
        })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
